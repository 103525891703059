import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';
import { CoreActions } from '../../store/actions';
import { IpService } from '../ip';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private _showCookieAcceptance$ = new BehaviorSubject(false);

  constructor(private actions$: Actions, private ipService: IpService, private http: HttpClient) {
    this.setShowCookieAcceptance();
  }

  get showCookieAcceptance$(): Observable<boolean> {
    return this._showCookieAcceptance$.asObservable();
  }

  acceptCookie(): Observable<void> {
    const baseURL = endpoints.cookies;
    const url = `${baseURL}/cookie-acceptance`;

    return this.ipService.getUserIp().pipe(switchMap((ip) => this.http.post<void>(url, { userIp: ip })));
  }

  private setShowCookieAcceptance(): void {
    this.actions$.pipe(ofType(CoreActions.ShowCookieAcceptance)).subscribe(() => {
      this._showCookieAcceptance$.next(true);
    });

    this.actions$.pipe(ofType(CoreActions.CookieAccepted)).subscribe(() => {
      this._showCookieAcceptance$.next(false);
    });
  }
}
