import { Config } from './config.interface';

export default {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',
    'CANCELAMENTO_BENEFICIÁRIO',

    'SESSAO_EXTRATO',
    'EXTRATO_COPART',
    'EXTRATO_COBRANCA',

    'SESSAO_PGTOS_E_DEBITOS',
    'DECL_ANUAL_DEB',
    'CARTA_QUITACAO',
    'INFORME_PGTOS',

    'SESSAO_SEGUNDA_VIA',
    'SEGUNDA_VIA_BOLETO',

    'SESSAO_DOWNLOADS',
    'GUIA_DO_CONSUMIDOR',
  ],
  labels: {
    MENU_INICIO: 'Início',
    MENU_SESSAO_CADASTROS: 'Movimentação',
    MENU_LISTA_BENEFICIÁRIOS: 'Visualizar Beneficiários',
    MENU_CANCELAMENTO_BENEFICIÁRIO: 'Cancelamento RN412',
    MENU_SESSAO_EXTRATO: 'Extrato',
    MENU_EXTRATO_COPART: 'Extrato de coparticipação',
    MENU_EXTRATO_COBRANCA: 'Extrato de cobrança',
    MENU_SESSAO_PGTOS_E_DEBITOS: 'Pagamentos e débitos',
    MENU_DECL_ANUAL_DEB: 'Declaração anual de débito',
    MENU_CARTA_QUITACAO: 'Carta de quitação',
    MENU_INFORME_PGTOS: 'Informe de pagamentos',
    MENU_SESSAO_SEGUNDA_VIA: '2º Via',
    MENU_SEGUNDA_VIA_BOLETO: 'Segunda via de boleto',
    MENU_SESSAO_DOWNLOADS: 'Downloads',
    MENU_GUIA_DO_CONSUMIDOR: 'Guia do consumidor',
    MENU_SESSAO_FAQ: 'Perguntas Frequentes',

    HEADER_SITE_INSTITUCIONAL: 'Site Institucional',
    DADOS_CADASTRAIS: 'Dados de cobrança',
  },
  configurations: {
    PORTAL_TITLE: 'Portal do Cliente',
    AUTH_SHOULD_SHOW_SUBTITLE: true,
    AUTH_SHOULD_SHOW_BUTTONS: true,
    FIRST_ACCESS_CODE_FIELD: 'WEB_VALIDATION_CODE',
    CAN_EDIT_BENEFICIARY: true,
    SHOW_TYPE_BENEFIT_FILTER: false,
    HOME_BANNER: false,
    COPART_COLUMNS: ['amount', 'billingDate', 'procedure', 'provider', 'quantity', 'serviceDate'],
    ENABLE_EMAIL_CREATE_ACCOUNT: false,
    EMAIL_NON_CONFIRMED_TEXT: 'O e-mail de acesso não foi confirmado! Para reenviar o e-mail de confirmação',
    SHOW_RESEND_EMAIL_LINK: true,
  },
} as Config;
