<form class="auth-container" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h5 class="w-100 mb-3">Insira suas credenciais</h5>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>CPF/CNPJ</mat-label>
    <input type="text" matInput formControlName="cgc" mask="CPF_CNPJ" />
    <mat-error *ngIf="formGroup.get('cgc')?.hasError('required')">Este campo é obrigatório</mat-error>
    <mat-error *ngIf="formGroup.get('cgc')?.hasError('mask')">Preencha este campo corretamente</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Senha</mat-label>
    <input type="password" matInput formControlName="password" />
    <mat-error *ngIf="formGroup.get('password')?.hasError('required')">Este campo é obrigatório</mat-error>
  </mat-form-field>

  <div class="links-container">
    <span routerLink="primeiro-acesso">1º Acesso</span>
    <span routerLink="recuperar-senha">Esqueci minha senha</span>
  </div>

  <div class="alert alert-danger w-100" role="alert" *ngIf="(InactivatedEmailError$ | async) && shouldShowInactivated">
    {{ emailNonConfirmedText }}
    <u class="c-pointer" (click)="resendEmailConfirmation()" *ngIf="showResendEmailLink">clique aqui</u>
  </div>

  <div class="alert alert-success w-100" role="alert" *ngIf="sentEmailConfirmation">
    O e-mail de confirmação foi enviado para o e-mail com sucesso!
  </div>

  <button mat-raised-button color="accent" type="submit">ENTRAR</button>
</form>
