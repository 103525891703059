import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, map, tap, take, filter, catchError, delay } from 'rxjs/operators';
import { DefaultErrorResponse } from '../models/DefaultErrorResponse';
import { SignInCredentials } from '../models/SignInCredentials';
import { AuthService } from '../services/auth';
import { EnterpriseService } from '../services/enterprise';
import { LayoutService } from '../services/layout/layout.service';
import { LoaderService } from '../services/loader';
import {
  CoreActions,
  loadEnterprise,
  setAppVersion,
  setAuthData,
  setEnterprise,
  showCookieAcceptance,
  signInError,
} from './actions';
import { IAppState } from './interfaces/IAppState';
import {
  selectIsCookieAccepted,
  selectEnterprise,
  selectHasEnterprise,
  selectIsAppVersionUpdated,
  selectIsLogged,
} from './selectors';
import packageJsonInfo from '../../../../package.json';
import { CookieService } from '../services/cookie';

@Injectable()
export class CoreEffects {
  redirectIfLoggedIn$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.RedirectIfLoggedIn),
        switchMap(() => this.store.select(selectIsLogged).pipe(take(1))),
        filter((v) => v),
        tap(() => this.router.navigate(['inicio']))
      );
    },
    {
      dispatch: false,
    }
  );

  signOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.SignOut),
        tap(() => this.router.navigate(['autenticacao']))
      );
    },
    {
      dispatch: false,
    }
  );

  signInAttempt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.SignInAttempt),
      tap((action) => {
        this.loader.show('Entrando...');
      }),
      switchMap((action: { payload: SignInCredentials }) =>
        this.authService.signIn(action.payload).pipe(
          catchError((e: DefaultErrorResponse) => {
            this.loader.hide();
            // eslint-disable-next-line ngrx/no-dispatch-in-effects
            this.store.dispatch(signInError({ payload: e }));
            throw e;
          })
        )
      ),
      tap(() => this.loader.hide()),
      tap(() => this.router.navigate(['inicio'])),
      map((response) => setAuthData({ payload: response }))
    );
  });

  initEnterprise$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.store.select(selectEnterprise).pipe(take(1))),
      tap((e) => e && this.layoutService.setEnterpriseCssColors(e)),
      switchMap(() => this.store.select(selectIsAppVersionUpdated).pipe(take(1))),
      switchMap((isUpdated) =>
        this.store.select(selectHasEnterprise).pipe(
          take(1),
          map((hasEnterprise) => !hasEnterprise || !isUpdated)
        )
      ),
      filter((v) => !!v),
      map(() => loadEnterprise())
    );
  });

  loadEnterprise$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.LoadEnterprise),
      tap(() => this.loader.show('Atualizando dados da empresa...')),
      switchMap(() => this.enterpriseService.getEnterprise()),
      tap(() => this.loader.hide()),
      tap((e) => this.layoutService.setEnterpriseCssColors(e)),
      map((enterprise) => setEnterprise({ payload: enterprise }))
    );
  });

  setProjectVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.SetEnterprise),
      map(() => setAppVersion({ payload: packageJsonInfo.version }))
    );
  });

  showCookieAcceptance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.store.select(selectIsCookieAccepted)),
      filter((isCookieAccepted) => !isCookieAccepted),
      delay(500),
      map(() => showCookieAcceptance())
    );
  });

  acceptCookie$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.CookieAccepted),
        switchMap(() => this.cookieService.acceptCookie())
      );
    },
    {
      dispatch: false,
    }
  );

  constructor(
    private store: Store<IAppState>,
    private actions$: Actions,
    private enterpriseService: EnterpriseService,
    private layoutService: LayoutService,
    private loader: LoaderService,
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService
  ) {}
}
