import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { LoaderService } from 'src/app/core/services/loader';

@Component({
  selector: 'app-show-beneficiary',
  templateUrl: './show-beneficiary.component.html',
  styleUrls: ['./show-beneficiary.component.scss'],
})
export class ShowBeneficiaryComponent implements OnInit {
  /**
   * Id do beneficiário encontrado
   * na URL
   */
  beneficiaryId!: string;

  /**
   * Beneficiário atual que será
   * exibido na tela
   */
  beneficiary?: Beneficiary;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private beneficiaryService: BeneficiaryService
  ) {}

  ngOnInit(): void {
    this.loadRouteParams();
    this.loadBeneficiary();
  }

  /**
   * Interpreta os route params
   */
  private loadRouteParams(): void {
    this.activatedRoute.params.subscribe(({ id }) => {
      this.beneficiaryId = id;
    });
  }

  /**
   * Carrega os dados do beneficiário
   * que será exibido na tela
   */
  private loadBeneficiary(): void {
    this.loader.show('Buscando dados do beneficiário...');
    this.beneficiaryService
      .findBeneficiaryById(this.beneficiaryId)
      .subscribe((beneficiary) => {
        this.beneficiary = beneficiary;
      })
      .add(() => {
        this.loader.hide();
      });
  }
}
