import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuscaExtratoCobrancaComponent } from './components/busca-extrato-cobranca';
import { BuscaExtratoCopartComponent } from './components/busca-extrato-copart';
import { ExtratoCobrancaComponent } from './components/extrato-cobranca';
import { ListaExtratoCopartComponent } from './components/lista-extrato-copart';

const routes: Routes = [
  { path: 'copart', component: BuscaExtratoCopartComponent },
  { path: 'copart/lista', component: ListaExtratoCopartComponent },
  { path: 'cobranca/busca', component: BuscaExtratoCobrancaComponent },
  { path: 'cobranca/:id', component: ExtratoCobrancaComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExtratoRoutingModule {}
