import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

import { ClientService } from './services/client';
import { KinshipService } from './services/kinship';
import { DocumentService } from './services/document';
import { ImmediateCancellationService } from './services/immediateCancellation';

import { DadosCadastraisRoutingModule } from './dados-cadastrais-routing.module';
import { ComponentsModule } from 'src/app/core/components/components.module';

import { DadosDeCobrancaComponent } from './components/dados-de-cobranca';
import { CancelamentoImediatoComponent } from './components/cancelamento-imediato';
import { TabelaCancelamentoImediatoComponent } from './components/tabela-cancelamento-imediato';
import { TabelaAlteracaoCadastralComponent } from './components/tabela-alteracao-cadastral';
import { AlteracaoCadastralComponent } from './components/alteracao-cadastral';
import { ModalCancelamentoImediatoComponent } from './components/modal-cancelamento-imediato';
import { BeneficiaryFormComponent } from './components/beneficiary-form';
import { BeneficiaryDocumentsComponent } from './components/beneficiary-documents';
import { BeneficiaryDocumentInputComponent } from './components/beneficiary-documents/beneficiary-document-input';
import { BeneficiaryPlansComponent } from './components/beneficiary-plans';
import { BeneficiaryPlanCardComponent } from './components/beneficiary-plans/beneficiary-plan-card';
import { BeneficiaryHealthStatementComponent } from './components/beneficiary-health-statement';
import { DpsQuestionsService } from './services/dpsQuestions';
import { ShowBeneficiaryComponent } from './components/show-beneficiary/show-beneficiary.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    TabelaAlteracaoCadastralComponent,
    AlteracaoCadastralComponent,
    DadosDeCobrancaComponent,
    CancelamentoImediatoComponent,
    TabelaCancelamentoImediatoComponent,
    ModalCancelamentoImediatoComponent,
    BeneficiaryFormComponent,
    BeneficiaryDocumentsComponent,
    BeneficiaryDocumentInputComponent,
    BeneficiaryPlansComponent,
    BeneficiaryPlanCardComponent,
    BeneficiaryHealthStatementComponent,
    ShowBeneficiaryComponent,
  ],
  imports: [
    CommonModule,
    DadosCadastraisRoutingModule,

    ComponentsModule,
    PipesModule,

    FormsModule,
    ReactiveFormsModule,

    MatTableModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatStepperModule,
    FontAwesomeModule,
    MatPaginatorModule,

    NgxMaskModule.forRoot(),
  ],

  providers: [ImmediateCancellationService, ClientService, KinshipService, DocumentService, DpsQuestionsService],
})
export class DadosCadastraisModule {}
