import { Component } from '@angular/core';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';

import packageJsonInfo from '../../../../../package.json';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise } from '../../store/selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  faPhoneAlt = faPhoneAlt;
  enterprise$ = this.store.select(selectEnterprise);
  version = packageJsonInfo.version;

  constructor(private store: Store<IAppState>) {}
}
