import { Action, createReducer, on } from '@ngrx/store';
import { Enterprise } from '../models/Enterprise';
import { SignInResponse } from '../models/SignInResponse';
import { User } from '../models/User';
import {
  closeSidenav,
  cookieAccepted,
  openSidenav,
  setAppVersion,
  setAuthData,
  setEnterprise,
  setUser,
  signOut,
  toggleSidenav,
} from './actions';
import { ICoreState } from './interfaces/ICoreState';
import packageJsonInfo from '../../../../package.json';

export const initialState: ICoreState = {
  sidenavOpened: true,
  appVersion: packageJsonInfo.version,
  isCookieAccepted: false,
};

const _setEnterprise = (state: ICoreState, action: { payload: Enterprise }): ICoreState => ({
  ...state,
  enterprise: action.payload,
});

const _setUser = (state: ICoreState, action: { payload: User }): ICoreState => ({
  ...state,
  user: action.payload,
});

const _setAuthData = (state: ICoreState, action: { payload: SignInResponse }): ICoreState => ({
  ...state,
  token: action.payload.token,
  user: action.payload.user,
  sidenavOpened: true,
});

const _signOut = (state: ICoreState): ICoreState => ({
  enterprise: state.enterprise,
  sidenavOpened: false,
  appVersion: state.appVersion,
  isCookieAccepted: state.isCookieAccepted,
});

const _toggleSidenav = (state: ICoreState): ICoreState => ({
  ...state,
  sidenavOpened: !state.sidenavOpened,
});

const _openSidenav = (state: ICoreState): ICoreState => ({
  ...state,
  sidenavOpened: true,
});

const _closeSidenav = (state: ICoreState): ICoreState => ({
  ...state,
  sidenavOpened: false,
});

const _setAppVersion = (state: ICoreState, action: { payload: string }): ICoreState => ({
  ...state,
  appVersion: action.payload,
});

const _cookieAccepted = (state: ICoreState): ICoreState => ({
  ...state,
  isCookieAccepted: true,
});

const _coreReducer = createReducer(
  initialState,
  on(setEnterprise, _setEnterprise),
  on(setUser, _setUser),
  on(setAuthData, _setAuthData),
  on(signOut, _signOut),
  on(toggleSidenav, _toggleSidenav),
  on(openSidenav, _openSidenav),
  on(closeSidenav, _closeSidenav),
  on(setAppVersion, _setAppVersion),
  on(cookieAccepted, _cookieAccepted)
);

export const coreReducer = (state: ICoreState | undefined, action: Action) => {
  return _coreReducer(state, action);
};
