import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  uploadFiles(files: File[]): Observable<string[]> {
    const url = endpoints.uploads;

    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));

    return this.http.post<string[]>(url, formData);
  }
}
