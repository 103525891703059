import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { parse } from 'date-fns';

import { map, switchMap, take, tap } from 'rxjs/operators';
import { SimpleBeneficiary } from '../../models/SimpleBeneficiary';
import { Beneficiary } from '../../models/Beneficiary';
import { endpoints } from 'src/environments/endpoints';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectUser, selectUserType } from '../../store/selectors';
import { CreateBeneficiaryPayload } from '../../models/CreateBeneficiaryPayload';
import { UploadService } from '../upload/upload.service';
import { BeneficiaryCardNumber } from '../../models/BeneficiaryCardNumber';
import { BeneficiaryEffectiveDate } from 'src/app/modules/dados-cadastrais/models/BeneficiaryEffectiveDate';
import { BenefitType } from '../../models/BenefitType';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryService {
  constructor(private http: HttpClient, private store: Store<IAppState>, private uploadService: UploadService) {}

  getBeneficiaries(benefitType?: string): Observable<SimpleBeneficiary[]> {
    const url = endpoints.beneficiaries;

    return this.store.select(selectUserType).pipe(
      take(1),
      switchMap((userType) => {
        const params: any = {
          typePerson: userType === 'PJ' ? 'j' : 'f',
        };

        if (benefitType) {
          params.benefitType = benefitType;
        }

        return this.http.get<{ beneficiaries: SimpleBeneficiary[] }>(url, { params });
      }),
      map((response) => response?.beneficiaries)
    );
  }

  updateBeneficiary(beneficiary: Beneficiary): Observable<void> {
    const baseURL = endpoints.beneficiaries;

    return this.store.select(selectUser).pipe(
      take(1),
      switchMap((user) =>
        this.http.put<void>(baseURL, {
          beneficiary,
          idClient: user?.idClient,
        })
      )
    );
  }

  getHolders(): Observable<SimpleBeneficiary[]> {
    return this.getBeneficiaries('1').pipe(map((r) => r.filter((b) => b.kinship === 'Titular')));
  }

  getDependents(holderId: string): Observable<SimpleBeneficiary[]> {
    return this.getBeneficiaries('1').pipe(
      map((r) =>
        r.filter((b) => {
          return b.idBeneficiary.substr(0, 26) === holderId.substr(0, 26);
        })
      )
    );
  }

  findBeneficiaryById(id: string): Observable<Beneficiary> {
    const baseURL = endpoints.beneficiaries;
    const url = `${baseURL}/${id}`;

    return this.http.get<Beneficiary>(url);
  }

  create(payload: CreateBeneficiaryPayload, documents: File[]): Observable<void> {
    const url = endpoints.beneficiaries;

    if (!documents.length) {
      return this.http.post<void>(url, payload);
    }

    return this.uploadService.uploadFiles(documents).pipe(
      tap((files) =>
        files.forEach((f, i) => {
          payload.documents[i].url = this.extractIdFromBlobImage(f);
        })
      ),
      switchMap(() => this.http.post<void>(url, payload))
    );
  }

  getCardNumbers(cgc: string, birthDate: string): Observable<BeneficiaryCardNumber[]> {
    const baseURL = endpoints.beneficiaries;
    const url = `${baseURL}/list-cardnumber`;

    const params: any = {
      cgc,
    };

    if (birthDate) {
      params.birthDate = parse(birthDate, 'dd/MM/yyyy', 0).toISOString();
    }

    return this.http.get<BeneficiaryCardNumber[]>(url, { params });
  }

  getBenefitTypes(): Observable<BenefitType[]> {
    const baseURL = endpoints.beneficiaries;
    const url = `${baseURL}/list-benefit-types`;

    return this.http
      .get<BenefitType[]>(url)
      .pipe(map((v) => v.sort((a, l) => (+a.type > +l.type ? 1 : +a.type < +l.type ? -1 : 0))));
  }

  getEffectiveDates(idBeneficiary?: string): Observable<BeneficiaryEffectiveDate> {
    const baseURL = endpoints.beneficiaries;
    const url = `${baseURL}/effective-date`;

    let params = new HttpParams();

    if (idBeneficiary) {
      params = params.append('idBeneficiary', idBeneficiary);
    }

    return this.http.get<BeneficiaryEffectiveDate>(url, { params });
  }

  private extractIdFromBlobImage(blobImageUrl: string): string {
    return blobImageUrl.slice(blobImageUrl.lastIndexOf('/') + 1);
  }
}
