import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComponentsModule } from './core/components/components.module';
import { ErrorHandlerInterceptor } from './core/interceptors/error-handler';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './core/interceptors/auth';
import { SharedModule } from './core/shared/shared.module';
import { registerLocaleData } from '@angular/common';

import localePt from '@angular/common/locales/pt';
import { AuthModule } from './modules/auth/auth.module';
import { DadosCadastraisModule } from './modules/dados-cadastrais/dados-cadastrais.module';
import { ExtratoModule } from './modules/extrato/extrato.module';
import { HomeModule } from './modules/home/home.module';
import { PagamentosEDebitosModule } from './modules/pagamentos-e-debitos/pagamentos-e-debitos.module';
import { SegundaViaModule } from './modules/segunda-via/segunda-via.module';
import { StoreModule } from '@ngrx/store';
import { coreReducer } from './core/store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { metaReducers } from './core/store/meta-reducers';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './core/store/effects';
import { PipesModule } from './core/pipes/pipes.module';
import { getPtPaginatorIntl } from './core/intl/paginator-intl';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ComponentsModule,

    ToastrModule.forRoot(),

    AuthModule,
    DadosCadastraisModule,
    ExtratoModule,
    HomeModule,
    PagamentosEDebitosModule,
    SegundaViaModule,
    SharedModule,
    PipesModule,

    StoreModule.forRoot({ core: coreReducer }, { metaReducers }),

    !environment.production ? StoreDevtoolsModule.instrument() : [],

    EffectsModule.forRoot([CoreEffects]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: MatPaginatorIntl,
      useValue: getPtPaginatorIntl(),
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'outline',
        showFirstLastButtons: true,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
