import { FormControl, ValidationErrors } from '@angular/forms';
import { isEmail } from './helpers';

export class CustomValidators {
  static email(control: FormControl): ValidationErrors | null {
    const value = control?.value;

    if (isEmail(value)) {
      return null;
    }

    return { invalidEmail: true };
  }
}
