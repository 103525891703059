import { Config } from './config.interface';
import copartColumnsMds from './configurations/copart-columns-mds';
import faqBlanc from './configurations/faq-blanc';
import faqIndra from './configurations/faq-indra';

export const minsait = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    HEADER_SITE_INSTITUCIONAL: 'De Bem Com a Vida',
    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  otherHeaderLinks: {
    'RH CONECTA': 'https://rhconecta.indrabrasil.com.br/me/solicitacoes',
  },
  configurations: {
    PORTAL_TITLE: 'Portal do Colaborador',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    HOME_BANNER_URL:
      'https://divitechblobportal.blob.core.windows.net/pdfcontainer/230613_MDS_DBCVSaúde_Julho_BannerIntranet_GR_V1.png',
    HOME_BANNER_LINK_TO:
      'https://debemcomavida.mdsgroup.com.br/conjuntivite-entenda-previna-se-e-cuide-dos-seus-olhos/?_gl=1*2cojjf*_up*MQ..*_ga*MjU5NDc2MjgzLjE2ODg3NDAzNDA.*_ga_PVV2LY00R7*MTY4ODc0MDMzOS4xLjAuMTY4ODc0MDMzOS4wLjAuMA..*_ga_43W2WYML5H*MTY4ODc0MDM0MS4xLjAuMTY4ODc0MDM0MS4wLjAuMA',
    FAQ_LIST: faqIndra,
    COPART_COLUMNS: copartColumnsMds,
  },
} as Config;

export const blanchospital = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    HEADER_SITE_INSTITUCIONAL: 'De Bem Com a Vida',
    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  otherHeaderLinks: {
    INTRANET: 'https://intranet.blanchospital.com.br/',
  },
  configurations: {
    PORTAL_TITLE: 'Portal do Colaborador',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    HOME_BANNER_URL:
      'https://divitechblobportal.blob.core.windows.net/pdfcontainer/230613_MDS_DBCVSaúde_Julho_BannerIntranet_GR_V1.png',
    HOME_BANNER_LINK_TO:
      'https://debemcomavida.mdsgroup.com.br/conjuntivite-entenda-previna-se-e-cuide-dos-seus-olhos/?_gl=1*2cojjf*_up*MQ..*_ga*MjU5NDc2MjgzLjE2ODg3NDAzNDA.*_ga_PVV2LY00R7*MTY4ODc0MDMzOS4xLjAuMTY4ODc0MDMzOS4wLjAuMA..*_ga_43W2WYML5H*MTY4ODc0MDM0MS4xLjAuMTY4ODc0MDM0MS4wLjAuMA',
    COPART_COLUMNS: copartColumnsMds,
    ENABLE_EMAIL_CREATE_ACCOUNT: true,
    FAQ_LIST: faqBlanc,
    EMAIL_NON_CONFIRMED_TEXT:
      'O e-mail de acesso não foi confirmado! Verifique sua caixa de e-mail, caso não encontre, faça o primeiro acesso novamente.',
    SHOW_RESEND_EMAIL_LINK: false,
  },
} as Config;

export const wtw = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  configurations: {
    PORTAL_TITLE: 'Portal do Colaborador',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: true,
    SHOW_TYPE_BENEFIT_FILTER: true,
    COPART_COLUMNS: copartColumnsMds,
    ENABLE_EMAIL_CREATE_ACCOUNT: true,
    FAQ_LIST: faqBlanc,
    EMAIL_NON_CONFIRMED_TEXT:
      'O e-mail de acesso não foi confirmado! Verifique sua caixa de e-mail, caso não encontre, faça o primeiro acesso novamente.',
    SHOW_RESEND_EMAIL_LINK: false,
  },
} as Config;

export const demonstracao = {
  menus: [
    'INICIO',

    'SESSAO_CADASTROS',
    'LISTA_BENEFICIÁRIOS',

    'SESSAO_CHAMADOS',
    'LISTA_CHAMADOS',

    'SESSAO_EXTRATO',
    'EXTRATO_COPART',
    'SESSAO_FAQ',
  ],
  labels: {
    MENU_SESSAO_CADASTROS: 'Meus Benefícios',
    MENU_SESSAO_CHAMADOS: 'Solicitações',
    MENU_LISTA_CHAMADOS: 'Minhas Solicitações',

    HEADER_SITE_INSTITUCIONAL: 'De Bem Com a Vida',
    DADOS_CADASTRAIS: 'Dados Cadastrais',
  },
  otherHeaderLinks: {
    'RH CONECTA': 'https://rhconecta.indrabrasil.com.br/me/solicitacoes',
  },
  configurations: {
    PORTAL_TITLE: 'Portal do Colaborador',
    AUTH_SHOULD_SHOW_SUBTITLE: false,
    AUTH_SHOULD_SHOW_BUTTONS: false,
    FIRST_ACCESS_CODE_FIELD: 'DATA_NASC',
    CAN_EDIT_BENEFICIARY: false,
    SHOW_TYPE_BENEFIT_FILTER: true,
    HOME_BANNER_URL:
      'https://divitechblobportal.blob.core.windows.net/portalcorretor/thumbnail_230123_MDS_DBCVSaúde_FEV_BannerDBCVcmCTA_GR_V01.png',
    HOME_BANNER_LINK_TO:
      'https://debemcomavida.mdsgroup.com.br/2023/01/30/terapia-aba-um-importante-metodo-para-ajudar-as-pessoas-com-transtorno-do-espectro-autista/',
    FAQ_LIST: faqIndra,
    COPART_COLUMNS: copartColumnsMds,
  },
} as Config;
