import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from '../../services/cookie';
import { cookieAccepted } from '../../store/actions';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise } from '../../store/selectors';

@Component({
  selector: 'app-cookie-acceptance',
  templateUrl: './cookie-acceptance.component.html',
  styleUrls: ['./cookie-acceptance.component.scss'],
})
export class CookieAcceptanceComponent {
  showCookieAcceptance$ = this.cookieService.showCookieAcceptance$;

  enterprise$ = this.store.select(selectEnterprise);

  constructor(private cookieService: CookieService, private store: Store<IAppState>) {}

  onAgreeClick(): void {
    this.store.dispatch(cookieAccepted());
  }
}
