<div class="footer-container" *ngIf="enterprise$ | async as enterprise">
  <div class="enterprise-contacts-container" *ngIf="enterprise.telefoneCentralAtendimento; else onlyImage">
    <div>
      <h6>Central de Atendimento</h6>
      <div class="email-text">{{ enterprise.emailCentralAtendimento }}</div>
      <div class="text-secondary text-phone">
        <fa-icon [icon]="faPhoneAlt"></fa-icon>
        {{ enterprise.telefoneCentralAtendimento }}
      </div>
    </div>
    <div class="logo-ans-container">
      <img class="logo-img" [src]="enterprise.logoTransparenteUrl" />
      <img class="ans-img" [src]="enterprise.ansImg" />
    </div>
  </div>

  <ng-template #onlyImage>
    <div class="d-flex justify-content-center">
      <img class="logo-img" [src]="enterprise.logoRodape" />
    </div>
  </ng-template>

  <div class="version-container">
    <small>Versão do app: {{ version }}</small>
  </div>
</div>
