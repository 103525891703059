<app-breadcrumb
  [steps]="[
    ['Início', 'inicio'],
    ['Selecionar Beneficiario', 'dados-cadastrais/selecionar-beneficiario'],
    [mode + ' ' + type, '']
  ]"
></app-breadcrumb>

<mat-card class="mt-4">
  <h4 class="mb-4">{{ mode }} {{ type }} {{ beneficiary?.name }}</h4>
  <mat-stepper orientation="vertical" linear *ngIf="showStepper">
    <mat-step [stepControl]="beneficiaryFormGroup">
      <ng-template matStepLabel>Dados do Beneficiário</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-form
          [type]="type"
          [mode]="mode"
          [beneficiaryId]="beneficiaryId"
          [beneficiary]="beneficiary"
          [beneficiaryParentId]="beneficiaryParentId"
          (saved)="onFormSaved($event)"
          (canceled)="onFormCanceled()"
        ></app-beneficiary-form>
      </ng-template>
    </mat-step>
    <mat-step *ngIf="mode === 'Adicionar'" [stepControl]="planFormGroup">
      <ng-template matStepLabel>Plano</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-plans
          [type]="type"
          [beneficiaryId]="beneficiaryParentId"
          [beneficiaryFormValue]="beneficiaryFormValue"
          (canceled)="goToStep(0)"
          (finished)="onPlanSelected($event)"
        ></app-beneficiary-plans>
      </ng-template>
    </mat-step>
    <mat-step *ngIf="mode === 'Adicionar'" [stepControl]="dpsFormGroup">
      <ng-template matStepLabel>Declaração de saúde</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-health-statement
          (canceled)="goToStep(1)"
          (saved)="onSaveDps($event)"
        ></app-beneficiary-health-statement>
      </ng-template>
    </mat-step>
    <mat-step *ngIf="mode === 'Adicionar'" [stepControl]="documentsFormGroup">
      <ng-template matStepLabel>Documentos</ng-template>
      <ng-template matStepContent>
        <app-beneficiary-documents
          (saved)="onDocumentsSaved($event)"
          (canceled)="goToStep(2)"
          [beneficiaryType]="type"
        ></app-beneficiary-documents>
      </ng-template>
    </mat-step>
  </mat-stepper>
</mat-card>
