<div>
  <p>
    Declaramos para os devidos fins e efeitos que o Sr.(a) {{ (user$ | async)?.nome || nomeTitular }} , CPF nº
    {{ (user$ | async)?.cgc || cgc || '' | mask: 'CPF_CNPJ' }}, pagou os valores abaixo discriminados, durante o ano de
    {{ year }} para a {{ (enterprise$ | async)?.clienteNome }}, registrado na ANS sob nº
    {{ (enterprise$ | async)?.registroAns }} e devidamente inscrita no CNPJ nº
    {{ (enterprise$ | async)?.cnpj ?? '' | mask: 'CPF_CNPJ' }}, na qualidade de administradora de benefícios do contrato
    Coletivo do plano de assistência à saúde , contrato nº 243 firmado com a(s) operadora(s) abaixo:
  </p>

  <ng-container *ngFor="let family of paymentReport.families; let familyIndex = index">
    <h1 style="border-top: 2px solid blue; padding-top: 10px; margin-top: 10px" *ngIf="familyIndex !== 0"></h1>
    <h6>
      Operadora:
      <span class="fw-light">{{ family.providerName }}</span>
    </h6>
    <h6>
      Contrato:
      <span class="fw-light">{{ family.contract }}</span>
    </h6>
    <h6>
      Entidade:
      <span class="fw-light">{{ family.entity }}</span>
    </h6>
    <br />

    <table class="tabela-informe-pagamentos">
      <thead>
        <tr>
          <th class="desc-valor">Usuário(a)</th>
          <th class="desc-valor">Parentesco</th>
          <th class="desc-valor">Descrição</th>
          <th class="espacamento">Jan</th>
          <th class="espacamento">Fev</th>
          <th class="espacamento">Mar</th>
          <th class="espacamento">Abr</th>
          <th class="espacamento">Mai</th>
          <th class="espacamento">Jun</th>
          <th class="espacamento">Jul</th>
          <th class="espacamento">Ago</th>
          <th class="espacamento">Set</th>
          <th class="espacamento">Out</th>
          <th class="espacamento">Nov</th>
          <th class="espacamento">Dez</th>
          <th>Total Geral</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let beneficiary of family.beneficiaries">
          <tr *ngFor="let detail of beneficiary.detail; let i = index">
            <ng-container *ngIf="i === 0">
              <td class="desc-valor espacamento-desc">{{ beneficiary.name }}</td>
              <td class="desc-valor espacamento-desc">{{ beneficiary.kinship }}</td>
            </ng-container>
            <ng-container *ngIf="i !== 0">
              <td></td>
              <td></td>
            </ng-container>
            <td class="desc-valor">{{ detail.description }}</td>
            <td class="desc-valor">{{ detail.january }}</td>
            <td class="desc-valor">{{ detail.february }}</td>
            <td class="desc-valor">{{ detail.march }}</td>
            <td class="desc-valor">{{ detail.april }}</td>
            <td class="desc-valor">{{ detail.may }}</td>
            <td class="desc-valor">{{ detail.june }}</td>
            <td class="desc-valor">{{ detail.july }}</td>
            <td class="desc-valor">{{ detail.august }}</td>
            <td class="desc-valor">{{ detail.september }}</td>
            <td class="desc-valor">{{ detail.october }}</td>
            <td class="desc-valor">{{ detail.november }}</td>
            <td class="desc-valor">{{ detail.december }}</td>
            <td class="desc-valor">{{ detail.total }}</td>
          </tr>
          <tr>
            <td colspan="16">&nbsp;</td>
          </tr>
        </ng-container>

        <tr>
          <td colspan="16">&nbsp;</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td class="fw-bold desc-valor">Valores Totais da Família</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.january }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.february }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.march }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.april }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.may }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.june }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.july }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.august }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.september }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.october }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.november }}</td>
          <td class="fw-bold desc-valor">{{ family.totalFamilyValue.december }}</td>
          <td class="fw-bold desc-valor">
            {{ family.totalFamilyValue.total }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
<div>
  <div _ngcontent-gwu-c190="" class="buttons-container"><button _ngcontent-gwu-c190="" (click)="printComponent()" mat-raised-button="" color="accent" class="mat-focus-indicator mat-raised-button mat-button-base mat-accent" ng-reflect-color="accent"><span class="mat-button-wrapper">Imprimir</span><span matripple="" class="mat-ripple mat-button-ripple" ng-reflect-disabled="false" ng-reflect-centered="false" ng-reflect-trigger="[object HTMLButtonElement]"></span><span class="mat-button-focus-overlay"></span></button></div>
</div>