import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ModalControl } from 'src/app/core/components/modal/ModalControl';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { FormModalCancelamentoImediato } from '../../models/FormModalCancelamentoImediato';
import { ImmediateCancellationRequest } from '../../models/ImmediateCancellationRequest';
import { SimpleBeneficiary } from '../../../../core/models/SimpleBeneficiary';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { ImmediateCancellationService } from '../../services/immediateCancellation';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { copyText } from 'src/app/core/utils/helpers';
import { bindCallback, Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectEnterprise, selectUser, selectUserType } from 'src/app/core/store/selectors';
import { BreadcrumbStep } from 'src/app/core/components/breadcrumb';

@Component({
  selector: 'app-cancelamento-imediato',
  templateUrl: './cancelamento-imediato.component.html',
  styleUrls: ['./cancelamento-imediato.component.scss'],
})
export class CancelamentoImediatoComponent implements OnInit {
  dataSource = new MatTableDataSource<SimpleBeneficiary>();

  beneficiaryId!: string;

  selection = new SelectionModel<SimpleBeneficiary>(true, []);

  modalControl = new ModalControl();

  modalFormValue!: FormModalCancelamentoImediato;

  userType$ = this.store.select(selectUserType);

  PJBreadcrumb: BreadcrumbStep = [
    ['Início', 'inicio'],
    ['Cancelamento imediato', 'dados-cadastrais/cancelamento-imediato/lista'],
    ['Selecionar beneficiarios', ''],
  ];

  PFBreadcrum: BreadcrumbStep = [
    ['Início', 'inicio'],
    ['Selecionar beneficiarios', ''],
  ];

  idClient!: string;
  idClientSub!: Subscription;

  backUrl$ = this.store.select(selectUserType).pipe(map((type) => (type === 'PJ' ? '../lista' : '/inicio')));

  constructor(
    private beneficiaryService: BeneficiaryService,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private notification: NotificationService,
    private store: Store<IAppState>,
    private immediateCancellationService: ImmediateCancellationService
  ) {}

  get displayedColumns(): string[] {
    const columns: string[] = [];

    if (this.dataSource?.data?.some((b) => b.status === 'Ativo')) {
      columns.push('select');
    }

    return [...columns, 'name', 'cpf', 'kinship'];
  }

  ngOnInit(): void {
    this.loadParams();
    this.loadTable();
    this.setBreadcrumbSteps();
    this.setIdClient();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(beneficiary: SimpleBeneficiary): void {
    if (!this.selection.isSelected(beneficiary)) {
      this.selectAllActivated();
    }
  }

  toggleSelection(beneficiary: SimpleBeneficiary, index: number): void {
    if (index === 0) {
      this.masterToggle(beneficiary);
      return;
    }

    this.selection.toggle(beneficiary);

    if (!this.selection.isSelected(beneficiary)) {
      this.selection.deselect(this.dataSource.data[0]);
    }
  }

  onSubmit(): void {
    if (this.selection.isEmpty()) {
      return;
    }

    this.modalControl.open();
    return;
  }

  async onModalClosed(formValue: FormModalCancelamentoImediato): Promise<void> {
    this.modalFormValue = formValue;

    this.sendRequest();
  }

  private setIdClient(): void {
    this.idClientSub = this.store
      .select(selectUser)
      .pipe(map((user) => user?.idClient))
      .subscribe((idClient) => {
        if (idClient) {
          this.idClient = idClient;
        }
      });
  }

  private setBreadcrumbSteps(): void {}

  private sendRequest(): void {
    const payload = this.getImmediateCancellationRequest();

    this.loader.show('Executando cancelamento imediato');
    this.immediateCancellationService
      .doImmediateCancellation(payload)
      .subscribe(({ protocol }) => {
        this.modalControl.close();
        this.showCopyProtocolModal(protocol);
        this.loadTable();
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private selectAllActivated(): void {
    const activatedBeneficiaries = this.dataSource.data.filter((b) => b.status === 'Ativo');

    this.selection.select(...activatedBeneficiaries);
  }

  private showCopyProtocolModal(protocol: string): void {
    this.notification
      .confirm({
        title: 'Beneficiário cancelado com sucesso.',
        text: `O código do protocolo é: ${protocol}`,
        icon: 'success',
        confirmButtonText: 'Copiar código',
        cancelButtonText: 'Fechar',
      })
      .pipe(filter((v) => v))
      .subscribe(() => {
        copyText(protocol);
      });
  }

  private loadParams(): void {
    this.activatedRoute.params.subscribe(({ id }) => {
      this.beneficiaryId = id;
    });
  }

  private loadTable(): void {
    this.loader.show('Buscando beneficiários...');

    this.store
      .select(selectUserType)
      .pipe(
        take(1),
        switchMap((userType) =>
          userType === 'PF'
            ? this.beneficiaryService.getBeneficiaries()
            : this.beneficiaryService.getDependents(this.beneficiaryId)
        )
      )
      .subscribe((beneficiaries) => {
        this.dataSource.data = beneficiaries;
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private getSelectedBeneficiaries(): SimpleBeneficiary[] {
    return this.selection.selected;
  }

  private getImmediateCancellationRequest(): ImmediateCancellationRequest {
    return new ImmediateCancellationRequest({
      idClient: this.idClient,
      account: String(this.modalFormValue.account),
      accountDigit: String(this.modalFormValue.accountDigit ?? ''),
      accountType: this.modalFormValue.accountType,
      agency: this.modalFormValue.agency,
      agencyDigit: String(this.modalFormValue.agencyDigit ?? ''),
      bank: this.modalFormValue.bank,
      cgc: this.modalFormValue.cgc,
      email: this.modalFormValue.email,
      name: this.modalFormValue.name,
      password: this.modalFormValue.password,
      reasonCancellation: this.modalFormValue.reasonCancellation,
      beneficiaries: this.dataSource.data.map((beneficiary) => ({
        selected: this.getSelectedBeneficiaries().includes(beneficiary),
        idBeneficiary: beneficiary.idBeneficiary,
      })),
    });
  }
}
