import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlteracaoCadastralComponent } from './components/alteracao-cadastral';
import { CancelamentoImediatoComponent } from './components/cancelamento-imediato';
import { DadosDeCobrancaComponent } from './components/dados-de-cobranca';
import { ShowBeneficiaryComponent } from './components/show-beneficiary';
import { TabelaAlteracaoCadastralComponent } from './components/tabela-alteracao-cadastral';
import { TabelaCancelamentoImediatoComponent } from './components/tabela-cancelamento-imediato';

const routes: Routes = [
  { path: 'selecionar-beneficiario', component: TabelaAlteracaoCadastralComponent },
  { path: 'alterar/:id', component: AlteracaoCadastralComponent },
  { path: 'visualizar/:id', component: ShowBeneficiaryComponent },
  { path: 'novo-dependente', component: AlteracaoCadastralComponent },
  { path: 'novo-titular', component: AlteracaoCadastralComponent },
  { path: 'dados-de-cobranca', component: DadosDeCobrancaComponent },
  { path: 'cancelamento-imediato/lista', component: TabelaCancelamentoImediatoComponent },
  { path: 'cancelamento-imediato/:id', component: CancelamentoImediatoComponent },
  { path: 'cancelamento-imediato', component: CancelamentoImediatoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DadosCadastraisRoutingModule {}
