import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader';
import { BeneficiaryPlan } from '../../models/BeneficiaryPlan';
import { PlanService } from '../../services/plan';
import { BeneficiaryType } from '../../types/BeneficiaryType';
import { Beneficiary } from 'src/app/core/models/Beneficiary';

@Component({
  selector: 'app-beneficiary-plans',
  templateUrl: './beneficiary-plans.component.html',
  styleUrls: ['./beneficiary-plans.component.scss'],
})
export class BeneficiaryPlansComponent implements OnInit {
  @Input() beneficiaryId!: string;
  @Input() type!: BeneficiaryType;
  @Input() beneficiaryFormValue!: Beneficiary;

  @Output() finished = new EventEmitter();
  @Output() canceled = new EventEmitter();

  plans!: BeneficiaryPlan[];

  selectedCardIndex: null | number = null;

  constructor(private planService: PlanService, private loader: LoaderService) {}

  ngOnInit(): void {
    this.loadPlans();
  }

  onSelectedChanged(index: number): void {
    this.selectedCardIndex = index;
  }

  onFinishClick(): void {
    if (this.getSelectedPlan()) {
      this.finished.emit(this.getSelectedPlan());
    }
  }

  onBackClick(): void {
    this.canceled.emit();
  }

  private loadPlans(): void {
    this.loader.show('Carregando planos disponíveis...');
    this.planService
      .getAvailablePlans(this.type, this.beneficiaryId, this.beneficiaryFormValue.birthDate)
      .subscribe((plans) => {
        this.plans = plans;
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private getSelectedPlan(): BeneficiaryPlan | null {
    if (this.selectedCardIndex == null) {
      return null;
    }

    return this.plans[this.selectedCardIndex];
  }
}
