import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Invoice } from 'src/app/core/models/Invoice';
import { InvoiceDetails } from 'src/app/core/models/InvoiceDetails';
import { InvoiceService } from 'src/app/core/services/invoice';
import { LoaderService } from 'src/app/core/services/loader';

@Component({
  selector: 'app-extrato-cobranca',
  templateUrl: './extrato-cobranca.component.html',
  styleUrls: ['./extrato-cobranca.component.scss'],
})
export class ExtratoCobrancaComponent implements OnInit {
  invoiceId!: string;

  invoice!: Invoice;

  dataSource = new MatTableDataSource<InvoiceDetails>();

  displayedColumns = ['debitCredit', 'userType', 'event', 'userName', 'amount', 'description'];

  loaded = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private invoiceService: InvoiceService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.loadParams();
    this.loadInvoiceDetails();
  }

  print(): void {
    print();
  }

  private loadParams(): void {
    this.activatedRoute.params.subscribe(({ id }) => {
      this.invoiceId = id;
    });
  }

  private loadInvoiceDetails(): void {
    this.loader.show('Buscando dados..');

    this.invoiceService
      .getInvoiceDetails(this.invoiceId)
      .subscribe((invoice) => {
        this.invoice = invoice;
        this.dataSource.data = invoice.details;
      })
      .add(() => {
        this.loaded = true;
        this.loader.hide();
      });
  }
}
