import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader';
import { SimpleBeneficiary } from 'src/app/core/models/SimpleBeneficiary';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { ModalControl } from 'src/app/core/components/modal/ModalControl';

@Component({
  selector: 'app-busca-extrato-copart',
  templateUrl: './busca-extrato-copart.component.html',
  styleUrls: ['./busca-extrato-copart.component.scss'],
})
export class BuscaExtratoCopartComponent implements OnInit {
  dataSource = new MatTableDataSource<SimpleBeneficiary>();

  displayedColumns = ['name', 'birthDate', 'kinship', 'cardNumber'];

  selectedBeneficiary!: SimpleBeneficiary;

  modalControl = new ModalControl();

  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.loadBeneficiaries();
  }

  selectBeneficiary(beneficiary: SimpleBeneficiary): void {
    this.selectedBeneficiary = beneficiary;

    this.modalControl.open();
  }

  searchCoparticipation(formData: any): void {
    this.modalControl.close();
    this.router.navigate(['extrato', 'copart', 'lista'], {
      queryParams: { ...formData, idBeneficiary: this.selectedBeneficiary.idBeneficiary },
    });
  }

  private loadBeneficiaries(): void {
    this.loaderService.show('Carregando Usuários...');
    this.beneficiaryService
      .getBeneficiaries()
      .subscribe((response) => {
        this.dataSource.data = response;
      })
      .add(() => {
        this.loaderService.hide();
      });
  }
}
