import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { endpoints } from 'src/environments/endpoints';
import { BeneficiaryPlan } from '../../models/BeneficiaryPlan';
import { BeneficiaryType } from '../../types/BeneficiaryType';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  constructor(private http: HttpClient) {}

  getAvailablePlans(
    beneficiaryType: BeneficiaryType,
    beneficiaryId?: string,
    beneficiaryBirthDate?: string
  ): Observable<BeneficiaryPlan[]> {
    const baseURL = endpoints.plans;
    const url = `${baseURL}`;

    let params = new HttpParams();
    params = params.set('beneficiaryType', String(beneficiaryType)[0]);

    if (beneficiaryId) {
      params = params.set('beneficiaryId', beneficiaryId);
    }

    if (beneficiaryBirthDate) {
      params = params.set('beneficiaryBirthDate', beneficiaryBirthDate);
    }

    return this.http.get<BeneficiaryPlan[]>(url, { params });
  }
}
