import { Component } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(public config: ConfigService) {}
}
