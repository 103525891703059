export default {
  'Vale Refeição/Alimentação': [
    {
      question: 'Há desconto em folha?',
      answer:
        'Sim, há desconto em folha, conforme PAT de até 20% ou de acordo com CCT. Exceto para estagiários. Para consulta é necessário abrir chamado',
    },
    {
      question: 'Há reajuste de valor?',
      answer: 'O reajuste de valor acontece anualmente mediante a CCT.',
    },
    {
      question: 'É pago em dinheiro?',
      answer: 'No mês da admissão o colaborador receberá o valor devido na folha de pagamento',
    },
    {
      question: 'Em quantos dias estará disponivel meu cartão Alelo?',
      answer: 'Em torno de 7 dias úteis a partir da emissão, o cartão chegará no RH e será  entregue ao funcionário.',
    },
    {
      question: 'Quando o benefício estará disponível?',
      answer: 'No último dia útil do mês',
    },
    {
      question: 'O crédito disponivel no último dia útil é para qual período de utilização?',
      answer: `O crédito que entrará no cartão no último dia útil refere-se a utilização do mês seguinte. </br>
               Ex.: Crédito disponível no dia 30/09 - Período para utilização : 01/10 a 31/10`,
    },
    {
      question: 'Quais estabelecimentos aceitam o Cartão Alelo? ',
      answer: `A rede credenciada está disponível no site: </br>
      `,
    },
    {
      question: 'Como consultar o Saldo do Cartão Alelo? ',
      answer:
        'Você pode consultar seu saldo pelo app Meu Alelo, pelo site <a href="www.meualelo.com.br">www.meualelo.com.br</a> ou pelo WhatsApp enviando uma mensagem para 011 4004-7733',
    },
    {
      question: 'Perdi meu Cartão Alelo, o que faço? Irei perder o saldo que havia no mesmo?',
      answer:
        'Você pode pedir a segunda via do seu cartão, clicando em “Perda ou roubo” no app Meu Alelo, ou solicitando pelo RH CONECTA.  O saldo existente será transferido para o novo cartão automaticamente.',
    },
    {
      question: 'Bloqueei meu cartão por perda, mas depois o encontrei. Posso desbloquear?',
      answer:
        'Nesse caso não é possível fazer o desbloqueio. Um outro cartão será enviado para você com prazo de entrega de até 7 dias úteis.',
    },
    {
      question: 'Fui demitido, meu Cartão Alelo é automaticamente cancelado?',
      answer: 'O saldo permanecerá em cartão até seu término.',
    },
    {
      question: 'Quando posso solicitar a troca de VR para VA? ',
      answer:
        'A alteração de modalidade do cartão poderá ser realizada nos meses de Abril, Agosto ou Dezembro. Enviar via RH Conecta até o dia 10 dos meses em referência.',
    },
  ],
  'Vale Transporte': [
    {
      question: 'Tem desconto?',
      answer:
        'O desconto é limitado 6% sobre o salário base, ou sobre o crédito, o valor que for menor. Exceto estagiários',
    },
    {
      question: 'É pago em dinheiro?',
      answer: 'No mês da admissão o colaborador receberá o valor devido na folha de pagamento',
    },
    {
      question: 'Quando o benefício estará disponível?',
      answer: 'No último dia útil do mês',
    },
    {
      question: 'Como faço a recarga?',
      answer:
        'A recarga é realizada automaticamente no cartão, porém, é necessário validar nos postos, Terminais e maquinas disponíveis tanto nos terminais de ônibus e ônibus da Sptrans, Metrô, CPTM e bancas de jornais.',
    },
    {
      question: 'Eu já tenho um Bilhete Único, poderei usar o mesmo?',
      answer:
        'É possível utilizar o mesmo bilhete, desde que esteja cadastrado na SPtrans com seu CPF e seja o último cartão emitido',
    },
    {
      question: 'Como consulto o saldo?',
      answer: `Você pode consultar nos postos, Terminais e maquinas disponíveis tanto nos terminais de ônibus e ônibus da Sptrans, Metrô, CPTM e bancas de jornais. 
      Os usuários que possuem cartão SPTrans Bilhete Único (São Paulo, capital), Cartão BOM (intermunicipal de São Paulo) e RioCard (Rio de Janeiro) podem consultar através do Whatsapp (11) 3003-8737 `,
    },
    {
      question: 'Houve reajuste no valor da minha tarifa, o que devo fazer?',
      answer: 'Solicitar a alteração via RH CONECTA. O RH realizará a próxima recarga já com o valor corrigido.',
    },
    {
      question: 'Segunda via do cartão/bilhete, como que devo fazer?',
      answer: `Para RIO CARD é necessário abrir a solicitação no RH CONECTA.
      Para as demais operadoras, a solicitação deve ser feita pela central ou posto de atendimento.`,
    },
    {
      question: 'Quero cancelar ou alterar meu VT, como faço?',
      answer:
        'Através do RH CONECTA, até dia 10 de cada mês, a solicitação será programada para o mês seguinte. Solicitações após dia 10, serão consideradas para o mês subsequente',
    },
  ],
  'Assistência Médica': [
    {
      question: 'Tem desconto?',
      answer: 'Desconto apenas para dependentes',
    },
    {
      question: 'Fui admitido hoje, amanhã já poderei usar a assistência médica?',
      answer: 'O funcionário admitido, será incluído no plano no dia 01 do mês seguinte.',
    },
    {
      question: 'Existe carência?',
      answer: 'A inclusão do funcionário deve ocorrer até 30 dias após a admissão, para que entre sem carência',
    },
    {
      question: 'Qual é o Plano de Saúde oferecido pela empresa?',
      answer: 'De acordo com  a localidade e eligibilidade do colaborador',
    },
    {
      question: 'Posso incluir minha esposa(o) / filhos no plano?',
      answer:
        'Sim, a solicitação deve ser feita pelo RH CONECTA, ou na admissão. Para inclusão sem carência, deve ser solicitado até 30 dias da data do evento (Exemplos: Admissão, casamento, nascimento, adoção)',
    },
    {
      question: 'Como posso ter acesso a carteirinha digital?',
      answer: 'Disponível no App da operadora',
    },
    {
      question: 'Como tenho acesso a rede credenciada / Atendimento?',
      answer: 'Disponível no App da operadora',
    },
    {
      question: 'Tenho direito a reembolso?',
      answer:
        'Para análise do limite de reembolso é necessário solicitar a prévia para sua operadora de saúde, através do aplicativo ',
    },
    {
      question: 'Tem coparticipação? ',
      answer:
        'Coparticipação de 30% do valor do procedimento, ou limitado a: 20,00 consulta; 30,00 Pronto Socorro; 10,00 exames simples',
    },
    {
      question: 'Vou receber carteirinha fisica?',
      answer: 'Não, as carteirinhas são apenas digitais, você pode consulta-la através do app ou site de sua operadora',
    },
    {
      question: 'Fui demitido, serei excluido automaticamente do plano?',
      answer: 'Haverá permanencia até o ultimo dia do mês de demissão. ',
    },
    {
      question: 'Remover dependente no benefício.',
      answer: `
        <strong>Como excluir um dependente? </strong> </br>
        Enviar via RH Conecta com uma carta de solicitação de próprio punho com os dados do titular e do dependente que deseja exclusão. </br>
        <strong>Quanto tempo para excluir?</strong> </br>
        
        Caso a solicitação seja até o dia 10, o cancelamento acontece no último dia do mês solicitado. Se a solicitação for após o dia 10, o cancelamento acontece no último dia do mês subsequente.
      `,
    },
    {
      question: 'Consulta ou Reclamação referentes a assistência médica.',
      answer: `
        <strong>Fui promovido de Rol, como faço para mudar meu plano?</strong></br>
        O seu plano será alterado automaticamente no mês subsequente, desde que seja elegível.  Reforçando que, conforme politica de benefícios da empresa, a elegibilidade do plano de saúde está atrelado ao Rol e localidade de trabalho.</br>
        <strong>Posso solicitar upgrade de plano? </trong> </br>
        Não há possibilidade de upgrade, os planos são de acordo com a localidade e rol do profissional. </br>
        <strong>Como faço para receber a carteirinha do convênio?  </trong> </br>
        As operadoras não emitem mais carteirinha, você poderá visualiza-las através do APP.
      `,
    },
  ],
  'Assistência Odontologica': [
    {
      question: 'Tem desconto?',
      answer: 'Depende da região, necessário consultar através do RH CONECTA',
    },
    {
      question: 'Posso incluir minha esposa(o) / filhos?',
      answer:
        'Apenas dependentes diretos (conjuge, filhos até 21 anos, ou até 24 se for universitário e companheiro (a) com declaração pública marital)',
    },
    {
      question: 'Posso incluir agregados (pai, mãe, irmãos)?',
      answer: 'Não é permitido inclusão de agregados',
    },
    {
      question: 'Como tenho acesso a rede credenciada / Atendimento?',
      answer: 'Através do App de sua operadora',
    },
    {
      question: 'Fui demitido, serei excluido automaticamente do plano?',
      answer: 'Haverá permanencia até o último dia do mês de demissão.',
    },
    {
      question: 'Fui demitido, posso dar continuidade na Assistência Odontológia?',
      answer:
        'Apenas se o titular teve contribuição em folha referente a mensalidade. Apenas para casos de demissão sem justa causa.',
    },
  ],
  'Auxílio Creche': [
    {
      question: 'Como funciona?',
      answer: `
      O benefício de Auxílio Creche é concedido ao colaborador que possui o direito conforme determina o ACT/CCT. </br>
      O envio deve ser realizado até o dia 18, referente ao pagamento da mensalidade da competência atual;
      `,
    },
    {
      question: 'Qual documentação preciso enviar?',
      answer: 'Inserir todos os documentos por categoria',
    },
    {
      question: 'Quais os valores a serem reembolsados?',
      answer: `
        SINDADOS BA - Até 24 meses - até R$ 351,23 </br>
        SINDADOS BA - De 24 a 60 meses - até R$ 234,15 </br>
        SINDPD PE - De 0 a 7 anos - até R$ 300,00 </br>
        SINDPD RJ - Até 7 meses após o retorno da Licença maternidade - até R$ 230,00 </br>
        SINDPD SP - Até 24 meses - até R$ R$571,48 </br>
        SINDPD SP - De 24 a 60 meses - até R$ 500,05 </br>
        SINDPD CE - FORTALEZA- até 6 meses - ATÉ R$ 182,00 </br>
      `,
    },
    {
      question: 'Como solicitar?',
      answer: 'Abrir a solicitação pelo RH Conecta',
    },
    {
      question: 'Durante as férias não tenho acesso ao RH Conecta, como solicito o reembolso?',
      answer: 'Realizar a solicitação através do portal beneficios',
    },
  ],
  Mobilidade: [
    {
      question: 'Quem tem direito?',
      answer:
        'O benefício de MOBILIDADE é concedido ao colaborador que trabalhe em escala noturna com saída após a meia noite e conforme elegibilidade de cargos da empresa para utilização de compra de combustível para o deslocamento Residência x Trabalho.',
    },
  ],
  'Seguro de vida': [
    {
      question: 'Consulta ou Reclamação referentes ao seguro de vida.',
      answer: `
        <strong>Gostaria de saber qual é a regra do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. As informações sobre a apólice serão encaminhadas na sua totalidade.</br>
        <strong>Quais são as coberturas do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. As informações sobre a apólice serão encaminhadas na sua totalidade.
      `,
    },
    {
      question: 'Solicitar Certificado (Apólice).',
      answer: `
        <strong>Qual é o procedimento para obter o meu certificado do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. Em seguida, será encaminhado o certificado com todas as coberturas e prêmio.</br>
        <strong>Quais são as coberturas do seguro de vida?</strong></br>
        Você precisa abrir o chamado, informando matrícula e nome completo. As informações sobre a apólice serão encaminhadas na sua totalidade.
      `,
    },
    {
      question: 'Solicitar alteração de beneficiário do Seguro de Vida.',
      answer: `
        <strong>Como posso alterar os beneficiários do meu Seguro de Vida? </strong></br>
        Você deverá enviar o formulário aqui no Portal de benefícios para alteração.
      `,
    },
  ],
};
