import { differenceInYears, parseISO } from 'date-fns';

export const isEmail = (email: string) => {
  const regExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailStr = String(email).toLowerCase();
  return regExp.test(emailStr);
};

export const safeJsonParse = (item: string): any => {
  try {
    return JSON.parse(item);
  } catch (e) {
    return null;
  }
};

export const safeParseISO = (date: string | Date): Date => {
  if (date instanceof Date) {
    return date;
  }

  return date ? parseISO(date) : (null as unknown as Date);
};

export const isValidDay = (day: number): boolean => day >= 1 && day <= 31;

export const isValidMonth = (month: number): boolean => month >= 1 && month <= 12;

export const isValidYear = (year: number): boolean => year >= 1900 && year <= 3000;

export const onlyNumbers = (value: string) => String(value).replace(/[^0-9]/g, '');

export const onlyLetters = (value: string) => String(value).replace(/[0-9]/g, '');

export const getCellPhoneMask = (strValue: string, withDDD = true): string => {
  const { length } = String(strValue);
  const ddd = withDDD ? '(00) ' : '';
  const cellphoneMask = '0 0000-0000';
  const phoneMask = '0000-00009';
  const mask = length > 10 ? cellphoneMask : phoneMask;

  return `${ddd}${mask}`;
};

export const isValidCep = (cepStr: string): boolean => {
  const value = String(cepStr);

  if (!hasOnlyNumbers(value)) {
    return false;
  }

  return value.length === 8;
};

export const isCpf = (cnpjOrCpf: string): boolean => {
  return cnpjOrCpf.length <= 11;
};

export const isCnpj = (cnpjOrCpf: string): boolean => {
  return cnpjOrCpf.length > 11 && cnpjOrCpf.length <= 14;
};

export const hasOnlyNumbers = (str: string): boolean => {
  return /^\d+$/.test(String(str));
};

export const formatProtheusBirthDate = (birthDate: string): string => {
  return birthDate?.split('-')?.reverse()?.join('-');
};

export const formatToProtheusBirthDate = (birthDateProtheus: string): string => {
  return birthDateProtheus?.split('-')?.reverse()?.join('-');
};

export const getDDDFromPhoneNumber = (phoneNumber: string): string => {
  return String(phoneNumber).slice(0, 2);
};

export const getPhoneNumberWithoutDDD = (phoneNumber: string): string => {
  return String(phoneNumber).slice(2);
};

export const downloadFile = (fileUrl: string): void => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = fileUrl;
  a.download = 'GCPS2019.pdf';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(fileUrl);
};

export const saveByteArray = (reportName: string, byte: any): void => {
  const blob = new Blob([byte], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};

export const copyText = async (text: string) => {
  await navigator.clipboard.writeText(text);
};

export const getLastYears = (count = 5, options?: { withCurrent: boolean }): number[] =>
  new Array(count).fill(null).map((_, i) => new Date().getFullYear() - i - (options?.withCurrent ? 0 : 1));

export const getCurrentMonthName = (): string =>
  [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ].find((_, i) => new Date().getMonth() === i) as string;

export const getAgeByBirthDate = (birthDate: Date): number => differenceInYears(new Date(), birthDate);

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const isEmptyObject = (obj: object): boolean => Object.keys(obj).length === 0;
