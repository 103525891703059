import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from './core/store/interfaces/IAppState';
import { selectIsLogged } from './core/store/selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLogged!: boolean;

  constructor(private store: Store<IAppState>, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.store.select(selectIsLogged).subscribe((v) => {
      this.isLogged = v;
      this.cd.detectChanges();
    });
  }
}
