import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { delay, filter, timeout } from 'rxjs/operators';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { CepService } from 'src/app/core/services/cep';
import { NotificationService } from 'src/app/core/services/notification';
import {
  getAgeByBirthDate,
  getDDDFromPhoneNumber,
  getPhoneNumberWithoutDDD,
  onlyNumbers,
} from 'src/app/core/utils/helpers';
import { KinshipService } from '../../services/kinship';
import { BeneficiaryType } from '../../types/BeneficiaryType';
import { parse } from 'date-fns';
import { LoaderService } from 'src/app/core/services/loader';
import { BeneficiaryKinship } from '../../models/BeneficiaryKinship';
import { Contract } from '../../models/Contract';
import { ClientService } from '../../services/client';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { BeneficiaryEffectiveDate } from '../../models/BeneficiaryEffectiveDate';
import { interval } from 'rxjs';

@Component({
  selector: 'app-beneficiary-form',
  templateUrl: './beneficiary-form.component.html',
  styleUrls: ['./beneficiary-form.component.scss'],
})
export class BeneficiaryFormComponent implements OnInit {
  @Input() type!: BeneficiaryType;

  @Input() mode!: 'Adicionar' | 'Editar';

  @Input() beneficiaryId!: string;

  @Input() beneficiaryParentId!: string;

  @Output() saved = new EventEmitter<Beneficiary>();

  @Output() canceled = new EventEmitter();

  formGroup!: FormGroup;

  kinships!: BeneficiaryKinship[];

  contracts!: Contract[];

  effectiveDate?: BeneficiaryEffectiveDate;

  private _beneficiary?: Beneficiary;

  constructor(
    private formBuilder: FormBuilder,
    private cepService: CepService,
    private notification: NotificationService,
    private kinshipService: KinshipService,
    private loader: LoaderService,
    private clientService: ClientService,
    private beneficiaryService: BeneficiaryService
  ) {}

  get selectedMaritalStatus(): string {
    return this.formGroup.get('maritalStatus')?.value ?? '';
  }

  get beneficiary(): Beneficiary | undefined {
    return this._beneficiary;
  }

  @Input() set beneficiary(_beneficiary: Beneficiary | undefined) {
    this._beneficiary = _beneficiary;

    if (_beneficiary) {
      interval(0)
        .pipe(delay(10))
        .subscribe(() => {
          this.fillForm();
        });
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadContracts();
    this.loadEffectiveDates();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return;
    }
    const beneficiary =
      this.mode === 'Editar' ? this.getUpdateBeneficiaryPayload() : this.getCreateBeneficiaryPayload();

    this.saved.emit(beneficiary);
  }

  loadCep(): void {
    const cepFormControl = this.formGroup.get('cep');
    const currentCep = cepFormControl?.value;

    if (cepFormControl?.invalid || !currentCep) {
      return;
    }

    this.cepService.getCepData(currentCep).subscribe((cepData) => {
      this.formGroup.patchValue({
        address: cepData.logradouro,
        district: cepData.bairro,
        city: cepData.localidade,
        uf: cepData.uf,
      });
    });
  }

  private loadContracts(): void {
    if (this.type !== 'Titular') {
      return;
    }

    this.loader.show('Carregando contratos');
    this.clientService
      .getContracts()
      .subscribe(
        (contracts) => {
          this.contracts = contracts;
        },
        () => {
          this.showNoPossibleAddBeneficiariesPopup({
            title: 'Você não pode adicionar uma nova família.',
            text: 'Não há nenhum contrato disponível',
          });
        }
      )
      .add(() => {
        this.loader.hide();
      });
  }

  private showNoPossibleAddBeneficiariesPopup({ title, text }: { title: string; text: string }): void {
    this.notification
      .confirm({
        title,
        text,
        confirmButtonText: 'Voltar para listagem',
        showCancelButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
      })
      .subscribe(() => {
        this.canceled.emit();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      cpf: [null, Validators.required],
      rg: [null, Validators.required],
      gender: [null, Validators.required],
      maritalStatus: [null, Validators.required],
      weddingDate: [null],
      birthDate: [null, Validators.required],
      motherName: [null, Validators.required],
      email: [null, Validators.required],
      homePhone: [null],
      cellNumber: [null, Validators.required],
      cep: [null, Validators.required],
      address: [null, Validators.required],
      addressNumber: [null, [Validators.required, Validators.maxLength(6)]],
      addressComplement: [null],
      district: [null, Validators.required],
      city: [null, Validators.required],
      uf: [null, Validators.required],
    });

    if (this.mode === 'Editar') {
      this.createControl('cardNumber');
      this.createControl('cardNumberOdonto');
      this.disableControls([
        'name',
        'cpf',
        'rg',
        'gender',
        'maritalStatus',
        'weddingDate',
        'birthDate',
        'motherName',
        'cardNumber',
        'cardNumberOdonto',
      ]);
      return;
    }

    this.createControls(['issuer', 'kinship']);
    this.setControlsRequired(['issuer', 'kinship']);
    this.registerKinshipLoad();

    if (this.type === 'Titular') {
      this.createControl('contract');
      this.formGroup.get('kinship')?.setValue('Titular');
      this.setControlRequired('contract');
    }

    this.createControls(['issuer', 'kinship']);
    this.setControlsRequired(['issuer', 'kinship']);
    this.registerKinshipLoad();
  }

  private disableControls(controlNames: string[]): void {
    controlNames.forEach(this.disableControl.bind(this));
  }

  private disableControl(controlName: string): void {
    this.formGroup.get(controlName)?.disable();
  }

  private enableControl(controlName: string): void {
    this.formGroup.get(controlName)?.enable();
  }

  private createControls(controlNames: string[]): void {
    controlNames.forEach(this.createControl.bind(this));
  }

  private createControl(controlName: string): void {
    this.formGroup.registerControl(controlName, this.formBuilder.control(null));
  }

  private setControlRequired(controlName: string): void {
    this.formGroup.get(controlName)?.setValidators(Validators.required);
  }

  private setControlsRequired(controlNames: string[]): void {
    controlNames.forEach(this.setControlRequired.bind(this));
  }

  private getUpdateBeneficiaryPayload(): Beneficiary {
    const form = this.formGroup.getRawValue();
    return {
      ...this.beneficiary!,
      name: form.name,
      cpf: onlyNumbers(form.cpf),
      rg: onlyNumbers(form.rg),
      gender: form.gender,
      maritalStatus: form.maritalStatus,
      birthDate: this.formatDate(form.birthDate, true),
      motherName: form.motherName,
      email: form.email,
      cep: form.cep,
      address: form.address,
      addressNumber: form.addressNumber,
      addressComplement: form.addressComplement,
      district: form.district,
      city: form.city,
      uf: form.uf,
      dddCell: getDDDFromPhoneNumber(form.cellNumber),
      cellNumber: getPhoneNumberWithoutDDD(form.cellNumber),
      dddHomePhone: getDDDFromPhoneNumber(form.homePhone),
      homePhone: getPhoneNumberWithoutDDD(form.homePhone),
    };
  }

  private getCreateBeneficiaryPayload(): Beneficiary {
    const form = this.formGroup.getRawValue();
    return {
      idBeneficiary: undefined,
      idEmployee: undefined,
      name: form.name,
      cpf: onlyNumbers(form.cpf),
      rg: onlyNumbers(form.rg),
      issuer: form.issuer,
      gender: form.gender,
      maritalStatus: form.maritalStatus,
      birthDate: this.formatDate(form.birthDate, true),
      motherName: form.motherName,
      email: form.email,
      cep: form.cep,
      address: form.address,
      addressNumber: form.addressNumber,
      addressComplement: form.addressComplement,
      district: form.district,
      city: form.city,
      uf: form.uf,
      dddCell: getDDDFromPhoneNumber(form.cellNumber),
      cellNumber: getPhoneNumberWithoutDDD(form.cellNumber),
      dddHomePhone: getDDDFromPhoneNumber(form.homePhone),
      homePhone: getPhoneNumberWithoutDDD(form.homePhone),
      status: 'Ativo',
      type: this.type,
      cardNumber: form.cardNumber,
      cardNumberOdonto: form.cardNumberOdonto,
      kinship: form.kinship,
      weddingDate: this.formatDate(form.weddingDate, true),
      healthPlan: {
        benefitType: '',
        accommodation: '',
        ans: '',
        coparticipation: false,
        coverage: '',
        entity: '',
        plan: '',
        provider: '',
        codeEntity: '',
        codeProvider: '',
        contract: '',
      },
    };
  }

  private formatDate(dateString: string, reverse = false, separator = '-'): string {
    dateString = onlyNumbers(dateString);
    const date = dateString?.slice(0, 2);
    const month = dateString?.slice(2, 4);
    const year = dateString?.slice(4, 8);

    let result = [date, month, year].filter(Boolean);

    if (reverse) {
      result = result.reverse();
    }

    return result.join(separator);
  }

  private fillForm(): void {
    if (!this.beneficiary) {
      return;
    }

    this.formGroup.patchValue({
      name: this.beneficiary.name,
      cpf: this.beneficiary.cpf,
      rg: this.beneficiary.rg,
      gender: this.beneficiary.gender,
      maritalStatus: this.beneficiary.maritalStatus,
      birthDate: this.formatDate(this.beneficiary.birthDate, false, '/'),
      motherName: this.beneficiary.motherName,
      email: this.beneficiary.email,
      homePhone: `${this.beneficiary.dddHomePhone}${this.beneficiary.homePhone}`,
      cellNumber: `${this.beneficiary.dddCell}${this.beneficiary.cellNumber}`,
      cep: this.beneficiary.cep,
      address: this.beneficiary.address,
      addressNumber: this.beneficiary.addressNumber,
      addressComplement: this.beneficiary.addressComplement,
      district: this.beneficiary.district,
      city: this.beneficiary.city,
      uf: this.beneficiary.uf,
      cardNumber: this.beneficiary.cardNumber,
      cardNumberOdonto: this.beneficiary.cardNumberOdonto,
    });
  }

  private loadKinships(): void {
    this.loader.show('Buscando graus de parentesco');
    this.kinshipService
      .getAll(this.getAge(), this.beneficiaryParentId)
      .subscribe((kinships) => {
        this.kinships = kinships;
        this.enableControl('kinship');
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private getAge(): number {
    const birthDateString = this.formGroup.get('birthDate')?.value;
    const birthDate = parse(birthDateString, 'ddMMyyyy', new Date());

    return getAgeByBirthDate(birthDate);
  }

  private registerKinshipLoad(): void {
    const birthDateControl = this.formGroup.get('birthDate');

    birthDateControl?.valueChanges
      .pipe(
        filter(() => this.type === 'Dependente'),
        filter(() => birthDateControl.value?.length === 8)
      )
      .subscribe(() => {
        this.loadKinships();
      });
  }

  private loadEffectiveDates(): void {
    const isAddMode = this.mode === 'Adicionar';
    const title = `Você não pode ${isAddMode ? 'adicionar um novo' : 'alterar este'} beneficiário.`;

    this.loader.show('Buscando Vigências disponíveis...');
    this.beneficiaryService
      .getEffectiveDates(this.beneficiaryId)
      .subscribe({
        next: (v) => {
          this.effectiveDate = v;
        },
        error: () => {
          this.showNoPossibleAddBeneficiariesPopup({
            title,
            text: 'Não há nenhuma data de vigência cadastrada.',
          });
        },
      })
      .add(() => this.loader.hide());
  }
}
