import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';
import { Invoice } from '../../models/Invoice';
import { SimpleInvoice } from '../../models/SimpleInvoice';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectTenantId } from '../../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {}

  getAllInvoices(openTitleOnly: boolean): Observable<SimpleInvoice[]> {
    const url = endpoints.invoice;
    const params = { openTitleOnly: Number(openTitleOnly) };

    return this.http.get<SimpleInvoice[]>(url, { params });
  }

  getInvoiceDetails(invoiceId: string): Observable<Invoice> {
    const baseURL = endpoints.invoice;
    const url = `${baseURL}/details/${invoiceId}`;

    return this.http.get<Invoice>(url);
  }

  sendInvoiceByEmail(invoiceId: string, clientId: string, branch: string): Observable<void> {
    const baseURL = endpoints.invoice;
    const url = `${baseURL}/send-by-email`;

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.store.select(selectTenantId).pipe(
      take(1),
      switchMap((tenantId) => {
        headers = headers.set('tenantid', `${tenantId},${branch}`);
        return this.http.post<void>(
          url,
          {
            invoiceId,
            clientId,
          },
          {
            headers,
          }
        );
      })
    );
  }

  downloadInvoice(invoiceId: string): Observable<any> {
    const baseURL = endpoints.invoice;
    const url = `${baseURL}/${invoiceId}`;

    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/pdf');

    return this.http.get(url, {
      headers,
      responseType: 'arraybuffer',
    });
  }

  publicDownloadInvoice(clientId: string, invoiceId: string, branch: string): Observable<any> {
    const baseURL = endpoints.invoice;
    const url = `${baseURL}/download/${clientId}/${invoiceId}`;

    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/pdf');

    return this.store.select(selectTenantId).pipe(
      take(1),
      switchMap((tenantId) => {
        headers = headers.set('tenantid', `${tenantId},${branch}`);
        return this.http.get(url, {
          headers,
          responseType: 'arraybuffer',
        });
      })
    );
  }
}
