import { Injectable } from '@angular/core';
import configs from 'src/app/config';
import { Config } from 'src/app/config/config.interface';
import { EnterpriseService } from '../enterprise';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config!: Config;

  constructor(private enterpriseService: EnterpriseService) {
    this.config = this.getConfig();
  }

  hasMenu(key: string): boolean {
    return !!this.config.menus?.includes(key);
  }

  getLabel(key: string): string {
    return this.config.labels?.[key] ?? key;
  }

  getConfiguration(key: string): any {
    return this.config.configurations?.[key];
  }

  private getConfig(): Config {
    const defaultConfig = configs.default;
    const config = configs[this.enterpriseService.getSubdomain()] ?? {};

    if (!config.menus) {
      config.menus = defaultConfig.menus;
    }

    config.labels = {
      ...defaultConfig.labels,
      ...config.labels,
    };

    config.configurations = {
      ...defaultConfig.configurations,
      ...config.configurations,
    };

    return config;
  }
}
