<h4>Bem vindo ao {{ config.getConfiguration('PORTAL_TITLE') }}!</h4>

<ng-container *ngIf="config.getConfiguration('HOME_BANNER_URL') as banner; else semBanner">
  <a *ngIf="config.getConfiguration('HOME_BANNER_LINK_TO') as linkTo" [href]="linkTo" target="_blank">
    <img class="img-banner" [src]="banner" alt="mds_banner" />
  </a>

  <img class="img-banner" [src]="banner" alt="mds_banner" *ngIf="!config.getConfiguration('HOME_BANNER_LINK_TO')" />
</ng-container>

<ng-template #semBanner>
  <p>Em breve uma descrição detalhada do sistema.</p>
</ng-template>
