<div class="beneficiary-plans-container">
  <app-beneficiary-plan-card
    *ngFor="let plan of plans; let i = index"
    [plan]="plan"
    [isSelected]="selectedCardIndex === i"
    (selectChanged)="onSelectedChanged(i)"
  ></app-beneficiary-plan-card>
</div>
<div class="mt-3">
  <button mat-stroked-button color="secondary" type="button" (click)="onBackClick()">Voltar</button>
  <button mat-raised-button color="accent" (click)="onFinishClick()" class="ms-2">Continuar</button>
</div>
