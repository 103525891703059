import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/core/services/config';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { UserService } from 'src/app/core/services/user';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectTenantNome } from 'src/app/core/store/selectors';
import { FirstAccessRequest } from '../../models/FirstAccessRequest';
import { format, parse } from 'date-fns';

@Component({
  selector: 'app-first-access',
  templateUrl: './first-access.component.html',
  styleUrls: ['./first-access.component.scss'],
})
export class FirstAccessComponent implements OnInit, OnDestroy {
  formGroup!: FormGroup;
  maskError = 'Selecione um perfil';
  mask!: string;
  userTypeChangeSub!: Subscription;

  tenantNome$ = this.store.select(selectTenantNome);

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private loader: LoaderService,
    private store: Store<IAppState>,
    private notification: NotificationService,
    public config: ConfigService
  ) {}

  get isCpf() {
    return this.formGroup.get('document')?.value?.length <= 11;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.userTypeChangeSub?.unsubscribe();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente.');
      return;
    }

    this.loader.show('Validando...');

    this.userService
      .firstAccess(this.getFirstAccessPayload())
      .subscribe((response) => {
        this.router.navigate(['autenticacao', 'criar-conta'], { state: { firstAccessResponse: response } });
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      webValidationNumber: ['', Validators.required],
      document: ['', [Validators.required]],
    });
  }

  private getFirstAccessPayload(): any {
    const formValue = this.formGroup.getRawValue();

    if (this.config.getConfiguration('FIRST_ACCESS_CODE_FIELD') === 'DATA_NASC' && this.isCpf) {
      const now = new Date();
      const parsed = parse(formValue.webValidationNumber, 'ddMMyyyy', now);
      const formatted = format(parsed, 'yyyyMMdd');

      return new FirstAccessRequest({
        webValidationNumber: formatted,
        document: formValue.document,
      });
    }

    return new FirstAccessRequest({
      webValidationNumber: formValue.webValidationNumber,
      document: formValue.document,
    });
  }
}
